import React from "react";
import { useOffersData } from "../../context/OffersDataProvider";
import JobBox from "../elements/JobBox";
import { TruncateText } from "../elements/SmallElements";
import Title from "../elements/Title";

export default function BestOffers() {
  const { data, error } = useOffersData();
  const first4Data = data?.slice(0, 4);
  if (error) {
    return <div>Error fetching data: {error}</div>;
  }
  return (
    <>
      <div className="container">
        <Title
          text="Consultez nos offres récentes"
          urlText="Découvrir plus"
          url="/offres-emploi"
        />
        <div className="row">
          {first4Data.map((item, index) => (
            <div key={index} className="col-12 col-xxl-6 mb-4">
              <JobBox
                img={item.image}
                title={TruncateText(item.title, 30)}
                info={item.info}
                url={item.url}
                tags={item.tags}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
