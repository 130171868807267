import React from "react";
import styled from "styled-components";
import { useCompanysData } from "../../context/CompanysDataProvider";
import { TEXT_COLOR_2 } from "../Variables";
import { Button } from "../elements/SmallElements";

export default function AboutSection() {
  const { data, error } = useCompanysData();
  const firsts5Data = data.slice(0, 5);
  const images = [
    {
      className: "icon_01",
      width: "40",
      height: "41",
    },
    {
      className: "icon_02",
      width: "49",
      height: "50",
    },
    {
      className: "icon_03",
      width: "86",
      height: "83",
    },
    {
      className: "icon_04",
      width: "38",
      height: "38",
    },
    {
      className: "icon_05",
      width: "67",
      height: "67",
    },
  ];
  const newImageArray = images.map((img, index) => ({
    ...img,
    src: firsts5Data[index]?.logo || "",
  }));
  if (error) {
    return <div>Error fetching data: {error}</div>;
  }
  return (
    <>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 order-lg-last">
            <div>
              <h2 className="title-one">
                Obtenez le travail de vos rêves rapidement.
              </h2>
              <p className="mt-4 md-mt-3 mb-4 md-mb-3">
                A full hybrid workforce management tools are yours to use, as
                well as access to our top 1% of talent.{" "}
              </p>
              <UlStyled>
                <li className="d-flex align-items-center">
                  <i className="fa-solid fa-check"></i>
                  <span>Filtres de recherche à distance avancés</span>
                </li>
                <li className="d-flex align-items-center">
                  <i className="fa-solid fa-check"></i>
                  <span>Obtenez le top 3% d’experts pour votre projet</span>
                </li>
                <li className="d-flex align-items-center">
                  <i className="fa-solid fa-check"></i>
                  <span>Obtenez le top 3% d’experts pour votre projet</span>
                </li>
              </UlStyled>
              <div className="mt-5">
                <Button text="Trouver un emploi" />
              </div>
            </div>
          </div>
          <div className="col-lg-7 order-lg-first">
            <ImagesStyled className="rounded-circle position-relative d-flex align-items-center justify-content-center ms-lg-5">
              <div className="inner-circle rounded-circle d-flex align-items-center justify-content-center">
                <img
                  alt="logo"
                  loading="lazy"
                  width="270"
                  decoding="async"
                  data-nimg="1"
                  className="lazy-img"
                  src="/img/logo.jpg"
                  style={{ color: "transparent" }}
                />
              </div>
              {newImageArray.map((item, index) => (
                <div
                  key={index}
                  className={`brand-icon rounded-circle d-flex align-items-center justify-content-center ${item.className}`}
                >
                  <img
                    alt=""
                    loading="lazy"
                    width={item.width}
                    height={item.height}
                    decoding="async"
                    data-nimg="1"
                    className="lazy-img"
                    src={item.src}
                    style={{ color: "transparent" }}
                  />
                </div>
              ))}
            </ImagesStyled>
          </div>
        </div>
      </div>
    </>
  );
}

const UlStyled = styled.div`
  list-style: none;
  li {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 24px;
    color: ${TEXT_COLOR_2};
    i {
      padding-right: 15px;
      font-size: 20px;
      color: ${TEXT_COLOR_2};
    }
  }
`;

const ImagesStyled = styled.div`
  width: 490px;
  height: 490px;
  z-index: 1;
  border: 1px solid #ccc;
  .inner-circle {
    width: 51%;
    height: 51%;
    border: 1px solid #ccc;
  }
  .brand-icon {
    background: #fff;
    position: absolute;
    z-index: 2;
    border: 1px solid #ccc;
    img {
      border-radius: 50%;
    }
  }
  .icon_01 {
    width: 65px;
    height: 65px;
    top: -25px;
    left: 50%;
    animation: jumpTwo 8s linear infinite;
  }
  .icon_02 {
    width: 100px;
    height: 100px;
    top: 19%;
    right: -4%;
    animation: jumpThree 8s linear infinite;
  }
  .icon_03 {
    width: 140px;
    height: 140px;
    bottom: -8%;
    right: 10%;
    animation: jumpFour 8s linear infinite;
  }
  .icon_04 {
    width: 80px;
    height: 80px;
    bottom: 8%;
    left: 4%;
    animation: jumpTwo 8s linear infinite;
  }
  .icon_05 {
    width: 100px;
    height: 100px;
    top: 22%;
    left: -7%;
    animation: jumpThree 8s linear infinite;
  }
`;
