import React from "react";
import styled from "styled-components";
import { BOX_SHADOW, PRIMARY_COLOR, WHITE_COLOR } from "../Variables";
import { Button, TruncateText } from "./SmallElements";

export default function CompanyBox({ item }) {
  const parsedUrl = item.offers && new URL(item.offersUrl);
  const companyName = parsedUrl && parsedUrl.pathname.split("/")[1];
  return (
    <>
      <Container>
        <img
          src={item.logo}
          alt="Logo"
          width="80"
          height="80"
          loading="lazy"
          decoding="async"
          className="lazy-img"
        />
        <div>{TruncateText(item.company, 15)}</div>
        <p>{TruncateText(item.info, 25)}</p>
        <Button
          text={item.offers.length > 0 ? item.offers : "Aucune offre"}
          // url={item.offersUrl.length > 0 ? `/entreprises/${companyName}` : "#"}
          url={item.offersUrl}
          newTab="yes"
          className="desabled"
        />
      </Container>
    </>
  );
}
const Container = styled.div`
  background-color: ${WHITE_COLOR};
  border-radius: 50px;
  padding: 40px 20px 35px;
  text-align: center;
  img {
    border-radius: 50%;
    -webkit-box-shadow: ${BOX_SHADOW};
    -moz-box-shadow: ${BOX_SHADOW};
    box-shadow: ${BOX_SHADOW};
  }
  div {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 25px;
  }
  p {
    margin-bottom: 35px;
    line-height: 2em;
  }
  a {
    font-size: 15px;
    color: ${PRIMARY_COLOR};
    padding: 4px 20px;
    border: 1px solid ${PRIMARY_COLOR};
    border-radius: 30px;
    font-weight: bold;
    transition: all 0.4s ease;
  }
  a:hover,
  a:focus {
    border: 1px solid ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
    background-color: ${PRIMARY_COLOR};
  }
`;
