import React from "react";

export default function NoData({ text }) {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="text-center">
          <i className="fa-solid fa-ban fs-1 TEXT_COLOR_1"></i>
          <span className="d-block TEXT_COLOR_1 fs-4 mt-2">{text}</span>
        </div>
      </div>
    </>
  );
}
