import axios from "axios";
import { motion } from "framer-motion";
import React, { useState } from "react";
import styled from "styled-components";
import { PRIMARY_COLOR, REACT_APP_API_URL } from "../Variables";
import { InputText, InputTextarea, Loader } from "../elements/SmallElements";

export default function Form() {
  let [fullName, setFullName] = useState("");
  let [subject, setSubject] = useState("");
  let [email, setEmail] = useState("");
  let [message, setMessage] = useState("");
  let [load, setLoad] = useState(false);
  let [statusMessage, setStatusMessage] = useState("");

  let handleFullName = (e) => {
    setFullName(e.target.value);
  };
  let handleSubject = (e) => {
    setSubject(e.target.value);
  };
  let handleEmail = (e) => {
    setEmail(e.target.value);
  };
  let handleMessage = (e) => {
    setMessage(e.target.value);
  };

  let sendMail = async () => {
    try {
      let response = await axios.post(
        `${REACT_APP_API_URL}/sendContactMail`,
        { fullName, subject, email, message }
      );
      if (response.data.status === "ok") {
        setStatusMessage("Email sent successfully!");
      } else {
        // setStatusMessage("Failed to send email. Please try again.");  
        <div className="text-danger text-center w-100">Failed to send email. Please <span
          style={{ color: 'black', cursor: 'pointer' }}
          className="fw-bold"
          onClick={refreshPage}
        >
          Try again
        </span></div>
      }
    } catch (error) {
      // setStatusMessage("An error occurred. Please try again later.");
      setStatusMessage(
        <div className="text-danger text-center w-100">An error occurred. Please <span
          style={{ color: 'black', cursor: 'pointer' }}
          onClick={refreshPage}
        >
          Try again
        </span></div>
      );
    } finally {
      setLoad(false);
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    if (fullName && subject && email && message) {
      setLoad(true);
      setStatusMessage("");
      await sendMail();
    }
  };

  return (
    <>
      {load && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          style={{ padding: "200px 0" }}
        >
          <Loader />
          <p className="text-center mt-5 pt-3 fw-bold TEXT_COLOR_1">
            Ne pas actualiser le navigateur
          </p>
        </motion.div>
      )}
      {statusMessage && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <p
            // className={
            //   statusMessage.includes("error") ? "text-danger text-center" : "text-success text-center"
            // }
            style={{ padding: "200px 0" }}
          >
            {statusMessage}
          </p>
        </motion.div>
      )}
      {!load && !statusMessage && (
        <motion.form
          onSubmit={handleSubmit}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <InputText
            type="text"
            name="name"
            id="name"
            placeholder="Nom complet"
            className="w-100 d-block mb-3"
            value={fullName}
            onChange={handleFullName}
            required
          />
          <InputText
            type="text"
            placeholder="Sujet"
            className="w-100 d-block mb-3"
            value={subject}
            onChange={handleSubject}
            required
          />
          <InputText
            type="email"
            placeholder="Email"
            className="w-100 d-block mb-3"
            value={email}
            onChange={handleEmail}
            required
          />
          <InputTextarea
            placeholder="Message..."
            cols="30"
            rows="10"
            className="w-100 d-block mb-3"
            value={message}
            onChange={handleMessage}
            required
          />
          <Send type="submit">
            <div class="svg-wrapper-1">
              <div class="svg-wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path
                    fill="currentColor"
                    d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                  ></path>
                </svg>
              </div>
            </div>
            <span>Envoyer</span>
          </Send>
        </motion.form>
      )}
    </>
  );
}

const Send = styled.button`
  font-family: inherit;
  font-size: 20px;
  background: ${PRIMARY_COLOR};
  color: white;
  padding: 0.3em 1.4em;
  padding-left: 1em;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  span {
    display: block;
    margin-left: 0.4em;
    transition: all 0.3s ease-in-out;
  }
  svg {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
  }

  &:hover .svg-wrapper {
    animation: fly-1 0.6s ease-in-out infinite alternate;
  }

  &:hover svg {
    transform: translateX(1.8em) rotate(45deg) scale(1.1);
  }

  &:hover span {
    transform: translateX(5em);
  }

  &:active {
    transform: scale(0.95);
  }

  @keyframes fly-1 {
    from {
      transform: translateY(0.1em);
    }

    to {
      transform: translateY(-0.1em);
    }
  }
`;
