import React from "react";
import styled from "styled-components";
import { PRIMARY_COLOR, TEXT_COLOR_1, WHITE_COLOR } from "../Variables";

export default function CheckBox({ ID, value, checked, onChange }) {
  return (
    <>
      <Container>
        <input
          type="checkbox"
          id={ID}
          value={value}
          checked={checked}
          onChange={onChange}
          className="hidden-xs-up cbxInput"
        />
        <label htmlFor={ID} className="cbx"></label>
      </Container>
    </>
  );
}

const Container = styled.div`
  position: relative;
  .cbx {
    position: relative;
    top: 1px;
    width: 18px;
    height: 18px;
    border: 1px solid ${TEXT_COLOR_1};
    border-radius: 3px;
    vertical-align: middle;
    transition: background 0.1s ease;
    cursor: pointer;
    display: block;
  }
  .cbx:after {
    content: "";
    position: absolute;
    top: 2px;
    left: 6px;
    width: 5px;
    height: 10px;
    opacity: 0;
    transform: rotate(45deg) scale(0);
    border-right: 2px solid ${WHITE_COLOR};
    border-bottom: 2px solid ${WHITE_COLOR};
    transition: all 0.3s ease;
    transition-delay: 0.15s;
  }
  .lbl {
    margin-left: 5px;
    vertical-align: middle;
    cursor: pointer;
  }
  .cbxInput:checked ~ .cbx {
    border-color: transparent;
    background: ${PRIMARY_COLOR};
    animation: jelly 0.6s ease;
  }
  .cbxInput:checked ~ .cbx:after {
    opacity: 1;
    transform: rotate(45deg) scale(1);
  }
  @keyframes jelly {
    from {
      transform: scale(1, 1);
    }
    30% {
      transform: scale(1.25, 0.75);
    }
    40% {
      transform: scale(0.75, 1.25);
    }

    50% {
      transform: scale(1.15, 0.85);
    }
    65% {
      transform: scale(0.95, 1.05);
    }
    75% {
      transform: scale(1.05, 0.95);
    }
    to {
      transform: scale(1, 1);
    }
  }
  .hidden-xs-up {
    display: none !important;
  }
`;
