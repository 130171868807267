import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useCompanysData } from "../../context/CompanysDataProvider";
import { BOX_SHADOW, WHITE_COLOR } from "../Variables";
import { Button } from "./SmallElements";

export default function CompanyOffers() {
  const {data, error} = useCompanysData();
  const first6Data = data.slice(0, 8);
  if (error) {
    return <div>Error fetching data: {error}</div>;
  }
  return (
    <>
      <Container>
        <div className="row">
          {first6Data.map((item, index) => (
            <div key={index} className="col-4 col-md-2 col-lg-3 mb-4">
              <Link to={item?.offersUrl} target="_blank">
                <img
                  alt={item?.company}
                  loading="lazy"
                  width="100%"
                  height="100%"
                  decoding="async"
                  className="lazy-img"
                  src={item?.logo}
                />
              </Link>
            </div>
          ))}
        </div>
        <div className="text-center mt-4">
          <Button text="Toutes les entreprises " url="/entreprises" newTab="no" />
        </div>
      </Container>
    </>
  );
}

const Container = styled.div`
  background-color: ${WHITE_COLOR};
  border-radius: 40px;
  padding: 25px;
  img {
    border-radius: 20px;
    -webkit-box-shadow: ${BOX_SHADOW};
    -moz-box-shadow: ${BOX_SHADOW};
    box-shadow: ${BOX_SHADOW};
  }
`;
