import Footer from "../components/Footer";
import Header from "../components/Header";
import { Section } from "../components/elements/SmallElements";
import AboutSection from "../components/home/AboutSection";
import BestOffers from "../components/home/BestOffers";
import BoxSection from "../components/home/BoxSection";
import Bottom from "../components/shapes/Bottom";
import Top from "../components/shapes/Top";
import { CompanysDataProvider } from "../context/CompanysDataProvider";
import { OffersDataProvider } from "../context/OffersDataProvider";

export default function Home() {
  return (
    <>
      <Header home={true} title=" Trouvez votre emploi sans aucun problème." />
      <Section className="BG_PRIMARY">
        <OffersDataProvider>
          <BestOffers />
        </OffersDataProvider>
        <Bottom />
      </Section>
      <Section>
        <CompanysDataProvider>
          <AboutSection />
        </CompanysDataProvider>
      </Section>
      <Section className="BG_PRIMARY">
        <Top />
        <CompanysDataProvider>
          <BoxSection />
        </CompanysDataProvider>
        <Bottom />
      </Section>
      <Footer />
    </>
  );
}
