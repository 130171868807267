import React from "react";
import styled from "styled-components";
import { useCompanysData } from "../../context/CompanysDataProvider";
import Navbar from "../Navbar";
import { BG_PRIMARY, WHITE_COLOR } from "../Variables";
import Bottom from "../shapes/Bottom";

export default function DetailsHeader({ name }) {
  const { data, error } = useCompanysData();
  const formattedText = name.replace(/-/g, " ");
  const getCompanyInfo = data.filter(
    (item) => item.company.toLowerCase() === formattedText.toLowerCase()
  );
  return (
    <>
      <Headers className="position-relative">
        <Navbar />
        <div className="page-title">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                {getCompanyInfo.map((item, index) => (
                  <div key={index} className="d-flex align-items-start">
                    <img src={item.logo} alt="" width="110px" height="110px" className="COMPANY_IMG" />
                    <div className="ms-4">
                      <span className="MARK bg-info text-white">{item.offers}</span>
                      <h1 className="text-white fs-1 my-2">{item.company}</h1>
                      <p className="text-light fw-bold fs-6">{item.info}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Bottom color={BG_PRIMARY} height="40px" />
      </Headers>
    </>
  );
}

const Headers = styled.header`
  &.home {
    h1 {
      font-size: 65px;
      margin-bottom: 0;
      @media screen and (max-width: 1399px) {
        font-size: 55px;
      }
      @media screen and (max-width: 1199px) {
        font-size: 45px;
      }
      @media screen and (max-width: 383px) {
        font-size: 35px;
      }
    }
    p {
      @media screen and (max-width: 383px) {
        font-size: 14px;
      }
    }
    .header-home-section {
      background-color: #016551 !important;
      @media screen and (max-width: 800px) {
        .shape-1 {
          width: 200px;
        }
        .shape-2 {
          width: 150px;
        }
        .shape-3 {
          width: 200px;
        }
      }
    }
  }
  .page-title {
    padding: 60px 0;
    background-color: #016551 !important;
    h1 {
      font-size: 50px;
      margin-bottom: 15px;
      @media screen and (max-width: 992px) {
        font-size: 30px;
      }
    }
    .breadcrumbs ol li {
      @media screen and (max-width: 540px) {
        font-size: 14px;
      }
      &::before {
        color: ${WHITE_COLOR};
        opacity: 0.75;
      }
    }
  }
  .avatar.avatar-md-sm {
    height: 45px;
    width: 45px;
  }
`;
