import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import CompanyOffers from "../components/elements/CompanyOffers";
import DetailsHeader from "../components/elements/DetailsHeader";
import JobBox from "../components/elements/JobBox";
import {
  DetailInfo,
  Loader,
  Section,
  SideBar,
} from "../components/elements/SmallElements";
import WeeksOffers from "../components/elements/WeeksOffers";
import Bottom from "../components/shapes/Bottom";
import { CompanysDataProvider } from "../context/CompanysDataProvider";
import { OffersDataProvider } from "../context/OffersDataProvider";
import { REACT_APP_API_URL } from "../components/Variables";

export default function CompanyDetail() {
  const [data, setData] = useState();
  const [load, setLoad] = useState(true);
  const currentUrl = window.location.href;
  const path = new URL(currentUrl).pathname;
  const desiredPart = decodeURIComponent(
    path && path.split("/entreprises/")[1]
  );
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.post(
        `${REACT_APP_API_URL}/getSingleCompany`,
        { name: desiredPart }
      );
      setData(response.data);
      setLoad(false);
    };
    fetchData();
  }, [desiredPart]);

  return (
    <>
      <CompanysDataProvider>
        <DetailsHeader big={false} name={desiredPart} />
      </CompanysDataProvider>
      <Section className="BG_PRIMARY">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8">
              <div className="row">
                <DetailInfo className="BG_PRIMARY py-0">
                  {!load ? (
                    data &&
                    data.map((item, index) => (
                      <div key={index} className="col-12 mb-4">
                        <JobBox
                          img={item.image}
                          title={item.title}
                          info={item.info}
                          url={item.url}
                          tags={item.tags}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="load-content">
                      <Loader />
                    </div>
                  )}
                </DetailInfo>
              </div>
            </div>
            <div className="col-12 col-lg-5 col-xl-4">
              <SideBar>
                <div className="row">
                  <div className="col-12 mb-4">
                    <OffersDataProvider>
                      <WeeksOffers />
                    </OffersDataProvider>
                  </div>
                  <div className="col-12">
                    <CompanysDataProvider>
                      <CompanyOffers />
                    </CompanysDataProvider>
                  </div>
                </div>
              </SideBar>
            </div>
          </div>
        </div>
        <Bottom />
      </Section>
      <Footer />
    </>
  );
}
