import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Hamburger } from "../components/elements/SmallElements";
import {
  BLACK_COLOR,
  PRIMARY_COLOR,
  WHITE_COLOR
} from "./Variables";

export default function Navbar() {
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => {
    setToggle(!toggle);
  };
  return (
    <>
      <Container className="d-flex align-items-center justify-content-between">
        <Logo className="logo"><img src="/img/logo.jpg" alt="" width="170px" /></Logo>
        <NavLinks
          className={`d-lg-flex align-items-center justify-content-between ${
            toggle ? "toggle" : ""
          }`}
        >
          <li>
            <Link to="/">Accueil</Link>
          </li>
          <li>
            <Link to="/offres-emploi">Offres d'emploi</Link>
          </li>
          <li>
            <Link to="/entreprises">Entreprises</Link>
          </li>
          <li>
            <Link to="/quiz">Quiz</Link>
          </li>
          <li>
            <Link to="/contactez-nous">Contactez-nous</Link>
          </li>
        </NavLinks>
        <NavButton className="rounded-4 py-3 px-4 d-none d-lg-block">
          <Link className="fs-6">publier une offre</Link>
        </NavButton>
        <Hamburger className="d-lg-none" onChange={handleToggle} />
      </Container>
    </>
  );
}

const Container = styled.nav`
  width: 100%;
  height: 100px;
  padding: 0 45px;
  background-color: ${WHITE_COLOR};
`;
const Logo = styled.div`
  font-size: 25px;
  font-weight: bold;
`;
const NavLinks = styled.ul`
  margin-bottom: 0;
  padding-left: 0;
  width: 40%;
  li {
    list-style: none;
    a {
      text-decoration: none;
      color: ${BLACK_COLOR};
      font-weight: bold;
    }
    a:not(.button) {
      border-bottom: none;
    }
  }
  @media screen and (max-width: 1344px) {
    width: 40%;
  } 
  @media screen and (max-width: 991px) {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.95);
    top: 98px;
    left: -100%;
    opacity: 0;
    width: 100%;
    text-align: center;
    padding: 50px 20px;
    z-index: 10;
    transition: all 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    li {
      margin-bottom: 50px;
      &:last-child {
        margin-bottom: 0;
      }
      a {
        color: ${WHITE_COLOR};
      }
    }
    &.toggle {
      left: 0;
      opacity: 1;
    }
  }
`;
const NavButton = styled.div`
  background-color: ${PRIMARY_COLOR};
  cursor: pointer;
  a {
    text-decoration: none;
    color: ${WHITE_COLOR};
    font-weight: bold;
  }
  a:not(.button) {
    border-bottom: none;
  }
`;
