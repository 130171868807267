import React, { useState } from "react";
import JobBox from "../../components/elements/JobBox";
import Pagination from "../../components/elements/Pagination";
import NoData from "../elements/NoData";

export default function DataFiltered({ data }) {
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = data.slice(startIndex, endIndex);
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };
  return (
    <>
      <div className="TEXT_COLOR_1 fs-5 mb-4">
        Les <b className="TEXT_PRIMARY">{data.length}</b> emplois
        trouvés
      </div>
      <div className="row">
        {currentPageData.map((item, index) => (
          <div key={index} className="col-12 mb-4">
            <JobBox
              img={item.image}
              title={item.title}
              info={item.info}
              tags= {item.tags}
              url={item.url}
            />
          </div>
        ))}
      </div>
      {data.length > 0 ? (
        data.length > 10 ? (
          <Pagination
            data={data}
            itemsPerPage={itemsPerPage}
            onChangePage={handleChangePage}
          />
        ) : (
          ""
        )
      ) : (
        <NoData text="Pas d'offre" />
      )}
    </>
  );
}
