import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useOffersData } from "../../context/OffersDataProvider";
import { BOX_SHADOW, PRIMARY_COLOR, WHITE_COLOR } from "../Variables";
import { TruncateText } from "../elements/SmallElements";
import { Button } from "./SmallElements";

export default function WeeksOffers() {
  const {data, error} = useOffersData();
  const flirts4Data = data.slice(0, 4);
  if (error) {
    return <div>Error fetching data: {error}</div>;
  }
  return (
    <>
      <Container>
        {flirts4Data.map((item, index) => (
          <div
            key={index}
            className="small-offer d-md-flex align-items-center text-center"
          >
            <img
              alt={item?.company}
              loading="lazy"
              width="40"
              height="40"
              decoding="async"
              className="lazy-img mb-2 mb-md-0"
              src={item?.image}
            />
            <div className="ps-md-4 my-md-0 small-offer-info">
              <Link to={item.url} target="_blank">
                <h2 className="p-0 m-0 mb-0 fs-6 text-dark">
                  {TruncateText(item?.title, 35)}
                </h2>
              </Link>
              <div className="d-flex align-items-center justify-content-center justify-content-md-start TEXT_COLOR_1 fw-bold">
                <span>{item?.info.date}</span>
                <i>|</i>
                <span>{item?.info.contract}</span>
                <i>|</i>
                <span>{item?.info.location}</span>
              </div>
            </div>
          </div>
        ))}
        <div className="text-center mt-5">
          <Button text="Toutes les offers" url="/offres-emploi" newTab="no" />
        </div>
      </Container>
    </>
  );
}
const Container = styled.div`
  background-color: ${WHITE_COLOR};
  border-radius: 40px;
  padding: 30px;
  @media screen and (max-width: 351px) {
    padding: 20px 10px;
  }
  .small-offer {
    margin-bottom: 30px;
    $:hover {
      background-color: red;
    }
    img {
      border-radius: 50%;
      -webkit-box-shadow: ${BOX_SHADOW};
      -moz-box-shadow: ${BOX_SHADOW};
      box-shadow: ${BOX_SHADOW};
    }
    .small-offer-info {
      i {
        margin: 0 5px;
      }
      span {
        font-size: 14px;
      }
      span:nth-child(3) {
        color: ${PRIMARY_COLOR};
      }
    }
  }
`;
