import React from "react";
import styled from "styled-components";
import "swiper/css";
import "swiper/css/navigation";
import { A11y, Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useCompanysData } from "../../context/CompanysDataProvider";
import { BOX_SHADOW, PRIMARY_COLOR, WHITE_COLOR } from "../Variables";
import CompanyBox from "../elements/CompanyBox";
import Title from "../elements/Title";

export default function BoxSection() {
  const { data, error } = useCompanysData();
  const first10Data = data.slice(0, 10); 
  if (error) {
    return <div>Error fetching data: {error}</div>;
  }
  return (
    <>
      <div className="container">
        <Title
          text="Meilleures Entreprises"
          urlText="Découvrir plus"
          url="/entreprises"
        />
        <Swp
          modules={[A11y, Autoplay, Navigation]}
          spaceBetween={30}
          slidesPerView={1}
          effect={"autoplay"}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          navigation
          breakpoints={{
            768: {
              slidesPerView: 2,
            },
            992: {
              slidesPerView: 3,
            },
            1213: {
              slidesPerView: 4,
            },
          }}
        >
          {first10Data.map((item) => (
            <SwiperSlide key={item.ID}>
              <CompanyBox item={item} />
            </SwiperSlide>
          ))}
        </Swp>
      </div>
    </>
  );
}

const Swp = styled(Swiper)`
  padding: 0 30px;
  .swiper-button-prev,
  .swiper-button-next {
    color: ${PRIMARY_COLOR};
    background-color: ${WHITE_COLOR};
    box-shadow: ${BOX_SHADOW};
    border-radius: 50%;
    width: 30px;
    height: 30px;
    &:after {
      font-size: 16px;
      font-weight: 800;
    }
  }
`;
