import { createBrowserRouter } from "react-router-dom";
import { CompanysDataProvider } from "../context/CompanysDataProvider";
import { OffersDataProvider } from "../context/OffersDataProvider";
import Company from "../pages/Company";
import CompanyDetail from "../pages/CompanyDetail";
import Home from "../pages/Home";
import OfferDetail from "../pages/OfferDetail";
import Offers from "../pages/Offers";
import Quiz from "../pages/Quiz";
import Contact from "../pages/Contact";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/offres-emploi",
    element: (
      <OffersDataProvider>
        <Offers />
      </OffersDataProvider>
    ),
  },
  {
    path: "/offres-emploi/*",
    element: (
      <OffersDataProvider>
        <OfferDetail />
      </OffersDataProvider>
    ),
  },
  {
    path: "/entreprises",
    element: (
      <CompanysDataProvider>
        <Company />
      </CompanysDataProvider>
    ),
  },
  {
    path: "/entreprises/*",
    element: <CompanyDetail />,
  },
  {
    path: "/quiz",
    element: <Quiz />,
  },
  {
    path: "/contactez-nous",
    element: <Contact />,
  },
]);

export default Router;
