import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { OneInputForm, Section } from "../components/elements/SmallElements";
import Bottom from "../components/shapes/Bottom";
import { CompanysDataProvider } from "../context/CompanysDataProvider";
import Navbar from "./Navbar";
import { BG_PRIMARY, WHITE_COLOR } from "./Variables";
import ImagesList from "./elements/ImagesList";

export default function Header({ home, title, page }) {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [shakeErr, setShakeErr] = useState(false);
  if (home) {
    const handleSubmit = (e) => {
      e.preventDefault();
      if (searchValue) navigate(`/offres-emploi?search=${searchValue}`);
      else {
        setShakeErr(true);
        setTimeout(() => {
          setTimeout(false);
        }, 100);
      }
    };
    return (
      <>
        <Headers className="home">
          <Navbar />
          <Section className="header-home-section position-relative">
            <div className="container">
              <div className="row g-4 align-items-center justify-content-between">
                <div className="col-lg-5">
                  <div className="title-heading">
                    <h1 className="heading fw-bold text-white mb-4">{title}</h1>
                    <p className="mb-5 text-light">
                      Trouvez des emplois, des possibilités d’emploi et de
                      carrière. Certaines des entreprises que nous avons aidé à
                      recruter d’excellents candidats au fil des ans.
                    </p>
                  </div>
                  <OneInputForm
                    type="text"
                    button="Trouvez"
                    placeholder="Recherche d’emplois, titre, etc..."
                    value={searchValue}
                    onchange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    onSubmit={handleSubmit}
                    className={`BG_PRIMARY ${shakeErr ? "shake" : ""}`}
                  />
                  <div className="mt-4 d-flex">
                    <CompanysDataProvider>
                      <ImagesList />
                    </CompanysDataProvider>
                    <span className="text-light ms-4">
                      <strong className="d-block fs-5">+200</strong>Entreprises
                    </span>
                  </div>
                </div>

                <div className="d-none d-lg-block col-lg-6">
                  <div className="position-relative ms-lg-5">
                    <img
                      src="/img/header/hero1.png"
                      className="img-fluid p-5"
                      alt=""
                    />
                    <div className="spinner">
                      <div className="position-absolute top-0 start-0 mt-lg-5 mt-4 ms-lg-5 ms-4">
                        <img
                          src="/img/header/circle-logo.png"
                          className="avatar avatar-md-sm rounded shadow p-2 bg-white"
                          alt=""
                        />
                      </div>
                      <div className="position-absolute top-0 start-50 translate-middle-x">
                        <img
                          src="/img/header/facebook-logo.png"
                          className="avatar avatar-md-sm rounded shadow p-2 bg-white"
                          alt=""
                        />
                      </div>
                      <div className="position-absolute top-0 end-0 mt-lg-5 mt-4 me-lg-5 me-4">
                        <img
                          src="/img/header/google-logo.png"
                          className="avatar avatar-md-sm rounded shadow p-2 bg-white"
                          alt=""
                        />
                      </div>
                      <div className="position-absolute top-50 start-0 translate-middle-y">
                        <img
                          src="/img/header/lenovo-logo.png"
                          className="avatar avatar-md-sm rounded shadow p-2 bg-white"
                          alt=""
                        />
                      </div>
                      <div className="position-absolute top-50 end-0 translate-middle-y">
                        <img
                          src="/img/header/android.png"
                          className="avatar avatar-md-sm rounded shadow p-2 bg-white"
                          alt=""
                        />
                      </div>
                      <div className="position-absolute bottom-0 start-0 mb-lg-5 mb-4 ms-lg-5 ms-4">
                        <img
                          src="/img/header/linkedin.png"
                          className="avatar avatar-md-sm rounded shadow p-2 bg-white"
                          alt=""
                        />
                      </div>
                      <div className="position-absolute bottom-0 start-50 translate-middle-x">
                        <img
                          src="/img/header/skype.png"
                          className="avatar avatar-md-sm rounded shadow p-2 bg-white"
                          alt=""
                        />
                      </div>
                      <div className="position-absolute bottom-0 end-0 mb-lg-5 mb-4 me-lg-5 me-4">
                        <img
                          src="/img/header/snapchat.png"
                          className="avatar avatar-md-sm rounded shadow p-2 bg-white"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="position-absolute top-0 end-0 z-0">
              <img
                src="/img/header/shape-3-soft-light.svg"
                alt="SVG"
                width="500"
                className="shape-1"
              />
            </div>
            <div className="position-absolute top-0 start-0 me-10 z-0">
              <img
                src="/img/header/shape-1-soft-light.svg"
                alt="SVG"
                width="250"
                className="shape-2"
              />
            </div>
            <div className="position-absolute bottom-0 start-0 z-0">
              <img
                src="/img/header/shape-2-soft-light.svg"
                alt="SVG"
                width="400"
                className="shape-3"
              />
            </div>
            <Bottom color={BG_PRIMARY} height="40px" />
          </Section>
        </Headers>
      </>
    );
  } else {
    return (
      <>
        <Headers className="position-relative">
          <Navbar />
          <div
            className="page-title"
            style={{ height: !page ? "400px" : "auto" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <h1 className="text-white">{title}</h1>
                  {page && (
                    <div className="breadcrumbs">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to="#" className="text-white opacity-75">
                              Accueil
                            </Link>
                          </li>
                          <li
                            className="breadcrumb-item text-white"
                            aria-current="page"
                          >
                            {page}
                          </li>
                        </ol>
                      </nav>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Bottom color={BG_PRIMARY} height="40px" />
        </Headers>
      </>
    );
  }
}

const Headers = styled.header`
  &.home {
    h1 {
      font-size: 65px;
      margin-bottom: 0;
      @media screen and (max-width: 1399px) {
        font-size: 55px;
      }
      @media screen and (max-width: 1199px) {
        font-size: 45px;
      }
      @media screen and (max-width: 383px) {
        font-size: 35px;
      }
    }
    p {
      @media screen and (max-width: 383px) {
        font-size: 14px;
      }
    }
    .header-home-section {
      background-color: #016551 !important;
      @media screen and (max-width: 800px) {
        .shape-1 {
          width: 200px;
        }
        .shape-2 {
          width: 150px;
        }
        .shape-3 {
          width: 200px;
        }
      }
    }
  }
  .page-title {
    padding: 60px 0;
    background-color: #016551 !important;
    h1 {
      font-size: 50px;
      margin-bottom: 15px;
      @media screen and (max-width: 992px) {
        font-size: 30px;
      }
    }
    .breadcrumbs ol li {
      @media screen and (max-width: 540px) {
        font-size: 14px;
      }
      &::before {
        color: ${WHITE_COLOR};
        opacity: 0.75;
      }
    }
  }
  .avatar.avatar-md-sm {
    height: 45px;
    width: 45px;
  }
`;
