import { motion } from "framer-motion";
import React, { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import CompanyBox from "../components/elements/CompanyBox";
import NoData from "../components/elements/NoData";
import Pagination from "../components/elements/Pagination";
import { InputText, Section } from "../components/elements/SmallElements";
import Bottom from "../components/shapes/Bottom";
import { useCompanysData } from "../context/CompanysDataProvider";

export default function Company() {
  const { data, error } = useCompanysData();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const itemsPerPage = 12;
  if (error) {
    return <div>Error fetching data: {error}</div>;
  }
  const filteredData = data.filter((item) =>
    item.company.toLowerCase().trim().includes(searchValue.toLowerCase().trim())
  );
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = filteredData.slice(startIndex, endIndex);
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };
  return (
    <>
      <Header home={false} title="Entreprises partenaires" page="Entreprises" />
      <Section className="BG_PRIMARY">
        <div className="container">
          <div className="row align-items-start justify-content-between mb-4">
            <div className="col-12 col-md-7 col-xl-9">
              <div className="TEXT_COLOR_1 fs-5">
                Toutes les <b className="TEXT_PRIMARY">{filteredData.length}</b>{" "}
                entreprises trouvées
              </div>
            </div>
            <div className="col-12 col-md-5 col-xl-3 mt-3 mt-md-0">
              <InputText
                type="search"
                placeholder="Rechercher..."
                className="w-100"
                value={searchValue}
                onChange={handleSearch}
              />
            </div>
          </div>
          <div className="row">
            {filteredData.length > 0 ? (
              currentPageData.map((item) => (
                <motion.div
                  key={item.ID}
                  className="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{
                    scale: 1,
                    opacity: 1,
                    transition: {
                      ease: [0.68, -0.55, 0.265, 1.55],
                      duration: 0.3,
                    },
                  }}
                >
                  <CompanyBox item={item} />
                </motion.div>
              ))
            ) : (
              <div className="py-5 my-5 ">
                <NoData
                  text={`Aucune entreprise avec le nom "${searchValue}"`}
                />
              </div>
            )}
            {filteredData.length > itemsPerPage ? (
              <div className="col-12 mt-4 d-flex justify-content-center">
                <div>
                  <Pagination
                    data={filteredData}
                    itemsPerPage={itemsPerPage}
                    onChangePage={handleChangePage}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <Bottom />
      </Section>
      <Footer />
    </>
  );
}
