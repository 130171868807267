import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BLACK_COLOR, PRIMARY_COLOR } from "../Variables";

export default function Title({ text, urlText, url }) {
  return (
    <>
      <Container className="row align-items-center justify-content-between mb-5 pb-4 px-3">
        <h2 className="col-12 col-sm-8 col-xl-10 p-0 m-0">{text}</h2>
        <div className="col-12 col-sm-4 col-xl-2 d-flex align-items-center justify-content-sm-end mt-3 mt-sm-0 ps-0">
          <TitleLink
            to={url}
            whileHover={{
              scale: 1.02,
              transition: { ease: "easeInOut", duration: 0.2 },
            }}
          >
            <span>{urlText}</span>
            <i className="fa-solid fa-angle-right ms-2"></i>
          </TitleLink>
        </div>
      </Container>
    </>
  );
}

const Container = styled.div`
  h2 {
    font-size: 50px;
    color: ${BLACK_COLOR};
  }
  @media screen and (max-width: 1399px) {
    h2 {
      font-size: 40px;
    }
  }
  @media screen and (max-width: 1199px) {
    h2 {
      font-size: 30px;
    }
  }
  @media screen and (max-width: 767px) {
    h2 {
      font-size: 24px;
    }
  }
`;
const TitleLink = styled(motion(Link))`
  &:not(.button) {
    color: ${PRIMARY_COLOR};
    border-bottom: 2px solide ${PRIMARY_COLOR};
    font-size: 18px;
    font-weight: bold;
    border-bottom: 2px solid ${PRIMARY_COLOR};
  }
  &:not(.button):hover {
    border-bottom: 2px solid ${PRIMARY_COLOR};
  }
  @media screen and (max-width: 1199px) {
    &:not(.button) {
        font-size: 16px;
    }
  }
`;
