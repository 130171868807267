import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  PRIMARY_COLOR,
  TEXT_COLOR_1,
  TEXT_COLOR_2
} from "./Variables";
import { OneInputForm } from "./elements/SmallElements";

export default function Footer() {
  return (
    <FooterConatiner>
      <div className="container mb-0">
        <div className="row justify-content-between mb-4">
          <div className="col-lg-4 col-md-3 mb-15">
            <div className="logo mb-15">
              <div className="logo">JOBS LOGO</div>
            </div>
          </div>
          <div className="col-lg-4 col-md-3 col-sm-4 mb-20">
            <h5>Liens utiles</h5>
            <ul>
              <li>
                <Link to="/">Accueil</Link>
              </li>
              <li>
                <Link to="#">Offres d'emploi</Link>
              </li>
              <li>
                <Link to="#">Entreprises</Link>
              </li>
              <li>
                <Link to="#">Quiz</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 mb-20">
            <h5>Newsletter</h5>
            <p className="mb-4">
              Joignez-vous et obtenez des nouveaux importants régulièrement.
            </p>
            <OneInputForm
              type="email"
              button="Send"
              placeholder="Enter your email*"
            />
            <p className="note ps-2 pt-2">
              We only send interesting and relevant emails.
            </p>
          </div>
        </div>
        <div className="row align-items-center copyright py-3">
          <div className="col-12">
            <p>Copyright © 2023 Tous droits réservés</p>
          </div>
        </div>
      </div>
    </FooterConatiner>
  );
}

const FooterConatiner = styled.footer`
  padding-top: 100px;
  ul {
    list-style: none;
    padding-left: 0;
    li {
      margin-bottom: 15px;
      a {
        color: ${TEXT_COLOR_2};
        font-size: 18px;
      }
      a:hover {
        color: ${PRIMARY_COLOR};
      }
    }
  }
  h5 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 30px;
  }
  p {
    color: ${TEXT_COLOR_2};
    font-size: 18px;
  }
  .note {
    color: ${TEXT_COLOR_1};
    font-size: 16px;
  }
  .copyright {
    p {
      font-size: 16px;
      color: ${TEXT_COLOR_1};
    }
  }
`;
