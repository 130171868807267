// Pagination.js
import React, { useState } from "react";
import styled from "styled-components";
import { BLACK_COLOR, PRIMARY_COLOR, WHITE_COLOR } from "../Variables";

const Pagination = ({ data, itemsPerPage, onChangePage }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handleClick = (page) => {
    setCurrentPage(page);
    onChangePage(page);
  };

  const handleFirstPage = () => {
    handleClick(1);
  };

  const handleLastPage = () => {
    handleClick(totalPages);
  };

  const renderPagination = () => {
    const pageButtons = [];

    for (let i = 1; i <= totalPages; i++) {
      pageButtons.push(
        <button
          key={i}
          onClick={() => handleClick(i)}
          className={currentPage === i ? "active number" : "number"}
        >
          {i}
        </button>
      );
    }

    return pageButtons;
  };

  return (
    <div>
      <Container className="d-sm-flex align-items-center text-center">
        <button
          className="fisrt"
          onClick={handleFirstPage}
          disabled={currentPage === 1}
        >
          <i className="fa-solid fa-angles-left"></i>
          <span className="ms-1 me-2">Prev</span>
        </button>
        <div className="my-3 my-sm-0">{renderPagination()}</div>
        <button
          className="last"
          onClick={handleLastPage}
          disabled={currentPage === totalPages}
        >
          <span className="me-1 ms-2">Last</span>
          <i className="fa-solid fa-angles-right"></i>
        </button>
      </Container>
    </div>
  );
};

export default Pagination;

const Container = styled.div`
  .number {
    border: none;
    margin: 0 5px;
    background-color: ${WHITE_COLOR};
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: ${BLACK_COLOR};
    transition: all 0.2s ease;
  }
  .number.active {
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
    font-weight: bold;
    transform: scale(1.1);
  }
  .fisrt,
  .last {
    border: none;
    background-color: transparent;
  }
`;
