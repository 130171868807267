import React from "react";
import styled from "styled-components";
import { useCompanysData } from "../../context/CompanysDataProvider";

export default function ImagesList() {
  const { data, error } = useCompanysData();
  const firsts4Data = data?.slice(0, 4);
  if (error) {
    return <div>Error fetching data: {error}</div>;
  }
  return (
    <>
      <ImagesLists>
        <ul>
          {firsts4Data?.map((item, index) => (
            <li key={index}>
              <figure>
                <img src={item.logo} className="img-fluid" alt="" />
              </figure>
            </li>
          ))}
          <li>
            <div className="img-coun">
              <i className="fa-solid fa-plus"></i>
            </div>
          </li>
        </ul>
      </ImagesLists>
    </>
  );
}

const ImagesLists = styled.div`
  position: relative;
  display: inline-flex;
  width: auto;
  ul {
    padding: 0;
    margin: 0;
    margin-left: 7px;
    display: inline-flex;
    align-items: center;
    list-style: none;
    li {
      display: inline-block;
      list-style: none;
      position: relative;
      .img-coun {
        height: 50px;
        width: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 14px;
        color: #1ca774;
        border: 2px solid white;
        border-radius: 50%;
        margin-left: -12px;
        background: #e7f4ff;
        box-shadow: 0px 2px 4px 2px rgb(0 0 0 / 10%);
        -webkit-box-shadow: 0px 2px 4px 2px rgb(0 0 0 / 10%);
        cursor: pointer;
      }
      figure {
        margin: 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 2px solid #ffffff;
        position: relative;
        margin-left: -12px;
        box-shadow: 0px 2px 4px 2px rgb(0 0 0 / 10%);
        -webkit-box-shadow: 0px 2px 4px 2px rgb(0 0 0 / 10%);
        img {
          border-radius: 50%;
        }
      }
    }
  }
`;
