import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  BOX_SHADOW,
  PRIMARY_COLOR,
  TEXT_COLOR_2,
  WHITE_COLOR
} from "../Variables";

export const TruncateText = (text, limit) => {
  return text && text.length > limit ? text.substring(0, limit) + "..." : text;
};

export const Button = ({ text, url, newTab, className, onClick }) => {
  return (
    <>
      <Buttons
        to={url}
        target={newTab === "yes" ? "_blank" : ""}
        className={className}
        onClick={onClick}
      >
        {text}
      </Buttons>
    </>
  );
};

export const InputText = ({ ...props }) => {
  return (
    <>
      <InputTexts {...props} />
    </>
  );
};
export const InputTextarea = ({ ...props }) => {
  return (
    <>
      <InputTextareas {...props} />
    </>
  );
};

export const Section = ({ children, ...props }) => {
  return (
    <>
      <Sections {...props}>{children}</Sections>
    </>
  );
};

export const OneInputForm = ({
  button,
  placeholder,
  type,
  value,
  onchange,
  ...props
}) => {
  return (
    <>
      <Form {...props}>
        <input
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={onchange}
        />
        <button type="submit">{button}</button>
      </Form>
    </>
  );
};

export const Hamburger = ({ onChange, ...props }) => {
  return (
    <>
      <Hamburgers {...props}>
        <input id="checkbox2" type="checkbox" onChange={onChange} />
        <label className="toggle toggle2" htmlFor="checkbox2">
          <div id="bar4" className="bars"></div>
          <div id="bar5" className="bars"></div>
          <div id="bar6" className="bars"></div>
        </label>
      </Hamburgers>
    </>
  );
};

export const Loader = () => {
  return <Loaders></Loaders>;
};

export const calculateHomeMatch = (dateString) => {
  if (dateString) {


    const currentDate = new Date();
    const givenDateStr = dateString;
    const givenDateParts = givenDateStr.split("-");
    const givenDate = new Date(
      parseInt(givenDateParts[2], 10),
      parseInt(givenDateParts[1], 10) - 1,
      parseInt(givenDateParts[0], 10)
    );
    const days = Math.floor((currentDate - givenDate) / (1000 * 60 * 60 * 24));
    const months = Math.abs(
      (givenDate.getFullYear() - currentDate.getFullYear()) * 12 +
      (givenDate.getMonth() - currentDate.getMonth())
    );
    if (months === 0) {
      if (days === 0) return "Aujourd'hui";
      return days === 1 ? `${days} Jour` : `${days} Jours`;
    }
    return `${months} mois`;
  } else {
    return null;
  }
};

export const SideBar = ({ children }) => {
  return <SideBars>{children}</SideBars>;
};

export const DetailInfo = ({ children, ...props }) => {
  return <DetailInfos {...props}>{children}</DetailInfos>;
};

const Buttons = styled(Link)`
  font-size: 15px;
  color: ${PRIMARY_COLOR} !important;
  padding: 4px 20px;
  border: 1px solid ${PRIMARY_COLOR} !important;
  border-radius: 30px;
  font-weight: bold;
  transition: all 0.4s ease;
  &:hover,
  &:focus {
    border: 1px solid ${PRIMARY_COLOR};
    color: ${WHITE_COLOR} !important;
    background-color: ${PRIMARY_COLOR};
  }
  @media screen and (max-width: 351px) {
    padding: 4px 15px;
    font-size: 14px;
  }
`;
const InputTexts = styled.input`
  width: 100%;
  height: 45px;
  padding: 12px;
  border-radius: 12px;
  border: 1.5px solid lightgrey;
  outline: none;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0px 0px 20px -18px;
  &:hover {
    border: 2px solid lightgrey;
    box-shadow: 0px 0px 20px -17px;
  }
  &:active {
    transform: scale(0.95);
  }
  &:focus {
    border: 2px solid grey;
  }
`;
const InputTextareas = styled.textarea`
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  border: 1.5px solid lightgrey;
  outline: none;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 0px 0px 20px -18px;
  &:hover {
    border: 2px solid lightgrey;
    box-shadow: 0px 0px 20px -17px;
  }
  &:active {
    transform: scale(0.95);
  }
  &:focus {
    border: 2px solid grey;
  }
`;

const Sections = styled.section`
  position: relative;
  padding: 100px 0;
  h2.title-one {
    font-size: 52px;
  }
  p {
    font-size: 18px;
    line-height: 2rem;
    color: ${TEXT_COLOR_2};
  }
`;

const Form = styled.form`
  width: 100%;
  height: 6vh;
  position: relative;
  border-radius: 15px;
  @media screen and (max-width: 480px) {
    height: 110px;
  }
  input {
    width: 100%;
    height: 100%;
    border: none;
    background-color: transparent;
    outline: none;
    padding: 0 100px 0 20px;
    @media screen and (max-width: 480px) {
      height: 60%;
    }
  }
  button {
    position: absolute;
    right: 10px;
    bottom: 50%;
    transform: translateY(50%);
    background-color: ${PRIMARY_COLOR};
    border: none;
    height: 70%;
    width: 20%;
    color: ${WHITE_COLOR};
    border-radius: 15px;
    font-weight: bold;
    @media screen and (max-width: 480px) {
      width: 96%;
      height: 40%;
      right: 50%;
      bottom: 5px;
      transform: translate(50%, 0%);
    }
  }
  &.shake {
    animation: shake 0.5s ease-in-out;
  }
  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
`;

const Hamburgers = styled.div`
  #checkbox2 {
    display: none;
  }
  .toggle2 {
    position: relative;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition-duration: 0.5s;
  }
  .bars {
    width: 100%;
    height: 5px;
    background-color: ${PRIMARY_COLOR};
    border-radius: 6px;
  }
  #bar5 {
    transition-duration: 0.8s;
  }
  #bar4,
  #bar6 {
    width: 80%;
  }
  #checkbox2:checked + .toggle2 .bars {
    position: absolute;
    transition-duration: 0.5s;
  }
  #checkbox2:checked + .toggle2 #bar5 {
    transform: scaleX(0);
    transition-duration: 0.5s;
  }
  #checkbox2:checked + .toggle2 #bar4 {
    width: 100%;
    transform: rotate(45deg);
    transition-duration: 0.5s;
  }
  #checkbox2:checked + .toggle2 #bar6 {
    width: 100%;
    transform: rotate(-45deg);
    transition-duration: 0.5s;
  }
  #checkbox2:checked + .toggle2 {
    transition-duration: 0.5s;
    transform: rotate(180deg);
  }
`;

const Loaders = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &::before,
  &::after {
    position: absolute;
    content: "";
    height: 8em;
    width: 8em;
    border: 1em solid ${PRIMARY_COLOR};
    border-radius: 50%;
    animation: loader_79178 2s linear infinite;
  }

  &::after {
    opacity: 0;
    animation-delay: 1s;
  }

  @keyframes loader_79178 {
    0% {
      border: 1em solid ${PRIMARY_COLOR};
      transform: scale(0);
      opacity: 1;
    }

    100% {
      border: 0 solid ${PRIMARY_COLOR};
      transform: scale(1);
      opacity: 0;
    }
  }
`;

const SideBars = styled.div`
  position: sticky;
  top: 20px;
`;

const DetailInfos = styled.div`
  min-height: 400px;
  height: auto;
  background-color: ${WHITE_COLOR};
  border-radius: 40px;
  padding: 30px;
  .load-content {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .detail-head {
    h1 {
      font-size: 30px;
    }
    img {
      border-radius: 50%;
      padding: 10px;
      -webkit-box-shadow: ${BOX_SHADOW};
      -moz-box-shadow: ${BOX_SHADOW};
      box-shadow: ${BOX_SHADOW};
    }
  }
  .detail-body {
    h2 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
      line-height: 22px;
      color: ${TEXT_COLOR_2};
    }
  }
`;
