import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  BLACK_COLOR,
  BOX_SHADOW,
  PRIMARY_COLOR,
  TEXT_COLOR_1,
  WHITE_COLOR,
} from "../Variables";
import { Button, TruncateText, calculateHomeMatch } from "./SmallElements";

export default function JobBox({ img, title, info, url, tags }) {
  const desiredPart = url && url.split("/offre-emploi/")[1];
  return (
    <>
      <Container
        className="position-relative"
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: { ease: "easeInOut", duration: 0.5 },
        }}
      >
        <div className="row justify-content-between align-items-center">
          <div className="col-12 col-md-9">
            <div className="d-md-flex align-items-center text-center text-md-start">
              <Link to="#">
                <img
                  alt="logo"
                  loading="lazy"
                  width="70"
                  height="70"
                  decoding="async"
                  className="lazy-img m-auto"
                  src={img}
                />
              </Link>
              <Title className="ps-md-4 my-4 my-md-0">
                <h2 className="p-0 m-0 mb-2">{TruncateText(title, 60)}</h2>
                <div className="d-flex flex-wrap align-items-center mb-2 fw-bold justify-content-center justify-content-md-start">
                  <div className="me-4 TEXT_COLOR_1">
                    <i className="fa-regular fa-clock"></i>
                    <span className="ms-2">
                      {calculateHomeMatch(info?.date)}
                    </span>
                  </div>
                  <div className="me-4 TEXT_COLOR_1">
                    <i className="fa-solid fa-location-dot"></i>
                    <span className="ms-2">{info?.location}</span>
                  </div>
                  <div className="me-4 TEXT_COLOR_1">
                    <i className="fa-solid fa-file-signature"></i>
                    <span className="ms-2">{info?.contract}</span>
                  </div>
                </div>
                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-start">
                  {tags &&
                    tags.map((item, index) => (
                      <div key={index} className="mb-2">
                        <span className="MARK TEXT_PRIMARY me-2">
                          <i className="fa-regular fa-star me-1"></i>
                          {item.startsWith("#") ? item.slice(1) : item}
                        </span>
                      </div>
                    ))}
                </div>
              </Title>
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="d-flex align-items-center justify-content-center justify-content-md-end">
              <Button
                text="POSTULER"
                // url={`/offres-emploi/${desiredPart}`}
                url={url}
                newTab="yes"
              />
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

const Container = styled(motion.div)`
  background-color: ${WHITE_COLOR};
  border-radius: 40px 35px;
  padding: 35px;
  a {
    color: ${BLACK_COLOR};
  }
  img {
    border-radius: 50%;
    padding: 5px;
    -webkit-box-shadow: ${BOX_SHADOW};
    -moz-box-shadow: ${BOX_SHADOW};
    box-shadow: ${BOX_SHADOW};
  }
`;
const Title = styled.div`
  h2 {
    font-size: 20px;
  }
  div {
    color: ${TEXT_COLOR_1};
    font-weight: bold;
    // i {
    //   margin: 0 10px;
    // }
    span:nth-child(3) {
      color: ${PRIMARY_COLOR};
    }
  }
`;
