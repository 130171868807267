import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import CompanyOffers from "../components/elements/CompanyOffers";
import {
  Button,
  DetailInfo,
  Loader,
  Section,
  SideBar,
  calculateHomeMatch,
} from "../components/elements/SmallElements";
import WeeksOffers from "../components/elements/WeeksOffers";
import { CompanysDataProvider } from "../context/CompanysDataProvider";
import { OffersDataProvider } from "../context/OffersDataProvider";
import Header from "../components/Header";
import {
  REACT_APP_API_URL,
  REACT_APP_OFFERS_LINK,
} from "../components/Variables";

export default function OfferDetail() {
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(true);
  const currentUrl = window.location.href;
  const path = new URL(currentUrl).pathname;
  const desiredPart = decodeURIComponent(
    path && path.split("/offres-emploi/")[1]
  );
  useEffect(() => {
    try {
      const fetchData = async () => {
        const response = await axios.post(`${REACT_APP_API_URL}/getSingleOffer`, {
          url: `${REACT_APP_OFFERS_LINK}/${desiredPart}`,
        });
        setData(response.data);
        setLoad(false);
      };
      fetchData();
    } catch (error) {
      console.error(error);
    }
  }, [desiredPart]);
  return (
    <>
      <Header home={false} />
      <Section className="BG_PRIMARY">
        <div className="container">
          <div className="row" style={{ marginTop: "-400px" }}>
            <div className="col-md-12 col-lg-8">
              <DetailInfo>
                {!load ? (
                  <>
                    <div className="detail-head d-flex align-items-start mb-5">
                      <img
                        src={data.image}
                        alt=""
                        width="120"
                        height="120"
                        decoding="async"
                        loading="lazy"
                      />
                      <div className="ms-4">
                        <h1>{data.title}</h1>
                        <span className="d-block my-2 TEXT_PRIMARY fw-bold">
                          {data.company}
                        </span>
                        <div className="d-flex flex-wrap align-items-center mb-2 fw-bold">
                          <div className="me-4 TEXT_COLOR_1">
                            <i className="fa-regular fa-clock"></i>
                            <span className="ms-2">
                              {calculateHomeMatch(data.date)}
                            </span>
                          </div>
                          <div className="me-4 TEXT_COLOR_1">
                            <i className="fa-solid fa-location-dot"></i>
                            <span className="ms-2">
                              {data.location.split("-")[1]}
                            </span>
                          </div>
                          <div className="me-4 TEXT_COLOR_1">
                            <i className="fa-solid fa-file-signature"></i>
                            <span className="ms-2">{data.contract}</span>
                          </div>
                          <div className="TEXT_COLOR_1">
                            <i className="fa-solid fa-globe"></i>
                            <span className="ms-2">
                              {data.language.startsWith("#")
                                ? data.language.slice(1)
                                : data.language}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-wrap align-items-center">
                          {data.skills.map((item, index) => (
                            <div key={index} className="mb-2">
                              <span className="MARK TEXT_PRIMARY me-2">
                                <i className="fa-regular fa-star me-1"></i>
                                {item.startsWith("#") ? item.slice(1) : item}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="detail-body">
                      {data.description ? (
                        <div className="mb-4">
                          <h2>Descriptif du poste :</h2>
                          <p>{data.description}</p>
                        </div>
                      ) : (
                        ""
                      )}
                      {data.profil ? (
                        <div className="mb-4">
                          <h2>Profil Recherché :</h2>
                          <p>{data.profil}</p>
                        </div>
                      ) : (
                        ""
                      )}
                      {data.more_Info ? (
                        <div>
                          <h2>Plus d'informations :</h2>
                          <p>{data.more_Info}</p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="mt-5 pt-2">
                        <Button
                          url={`${REACT_APP_OFFERS_LINK}/${desiredPart}`}
                          text="POSTULER"
                          className="w-100 d-block text-center"
                          newTab="yes"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="load-content">
                    <Loader />
                  </div>
                )}
              </DetailInfo>
            </div>
            <div className="col-12 col-lg-5 col-xl-4">
              <SideBar>
                <div className="row">
                  <div className="col-12 mb-4">
                    <OffersDataProvider>
                      <WeeksOffers />
                    </OffersDataProvider>
                  </div>
                  <div className="col-12">
                    <CompanysDataProvider>
                      <CompanyOffers />
                    </CompanysDataProvider>
                  </div>
                </div>
              </SideBar>
            </div>
          </div>
        </div>
      </Section>
      <Footer />
    </>
  );
}
